<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    :has-insert-btn="false"
    :allow-duplicate="false"
    hide-delete
    authority="General_MainParameter"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './storeModule'

export default {
  components: {
    EntityList,
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'main-parameters',
        endpoint: 'main-parameters',
        route: 'main-parameters',
        title: {
          single: this.$i18n.t('Main Parameter'),
          plural: this.$i18n.t('Main Parameters'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'rate', label: this.$t('Value'), sortable: false },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
        },
        {
          id: 'rate',
          type: 'number',
          label: this.$t('Value'),
          required: true,
        },
        {
          id: 'description',
          type: 'textarea',
          label: this.$t('Description'),
        },
        // {
        //   id: 'minVal',
        //   type: 'number',
        //   label: this.$t('Min Value'),
        // },
        // {
        //   id: 'maxVal',
        //   type: 'number',
        //   label: this.$t('Max Value'),
        // },
      ]
    },
  },
}
</script>
